import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import moment from 'moment';
import {getRootStore} from '../../app.bootstrap';
import {
    IWifiAnalyzerController,
    IWifiAnalyzerResults,
    WifiAnalyzerController
} from '../../components/wifi-analyzer/controller';
import {NATIVE_PLATFORM_TYPE} from '@techsee/techsee-common/lib/constants/room.constants';
import {capitalize, toUpper} from 'lodash';

const PAGE_SIZE = 25;

export interface IWifiAnalyzerHistoryController {
    dateFormat(date: string, format: string): string;
    selectSession(session: IWifiAnalyzerResults): void;
    nextPage(): void;
    prevPage(): void;
    goToInvitePage?: () => void;
    getDateFormattedByAcc: (date: string, withHours?: boolean) => string;
    searchHistory: () => Promise<void>;
    updateWifiAnalyzer: (newList: any) => void;
    wifiAnalyzerController: IWifiAnalyzerController;
    platformNameFormatted: (deviceName: NATIVE_PLATFORM_TYPE) => string;

    readonly translate: ITranslate;
    readonly wifiAnalyzerList: IWifiAnalyzerResults[];
    readonly endDate: string;
    readonly startDate: string;
    readonly customerRef: string;
    readonly selectedSession: any;
    readonly currentPage: number;
    readonly totalPages: number;
    readonly isNextAvailable: boolean;
    readonly isPrevAvailable: boolean;
    readonly getDataOnInitialLoad: boolean;
    readonly openModal: boolean;
}

type SearchCriteria = {
    startDate?: string;
    endDate?: string;
    customerRef?: string;
    roomId?: string;
};

export class WifiAnalyzerHistoryController implements IWifiAnalyzerHistoryController {
    private readonly _translate: ITranslate;
    private readonly _startDate: string;
    private readonly _endDate: string;
    private readonly _customerRef: string;
    private readonly _roomId: string;
    private readonly accountId: string;
    private readonly _wifiAnalyzerController: IWifiAnalyzerController;

    @observable private _wifiAnalyzerList: IWifiAnalyzerResults[] = [];
    @observable private _selectedSession: any = {} as any;
    @observable private _totalPages: number = 1;
    @observable private _currentPage: number = 1;
    @observable private _getDataOnInitialLoad: boolean = false;
    @observable private readonly _accDateFormat: string = '';
    @observable private readonly _timeZone: string = '';
    @observable private readonly _language: string = '';
    @observable private _openModal: boolean = false;

    readonly goToInvitePage?: () => void;

    constructor(
        translate: ITranslate,
        accountId: string,
        accDateFormat: string,
        timeZone: string,
        language: string,
        searchCriteria: SearchCriteria,
        goToInvitePage?: () => void
    ) {
        this.selectSession = this.selectSession.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.getDateFormattedByAcc = this.getDateFormattedByAcc.bind(this);
        this._translate = translate;
        this.goToInvitePage = goToInvitePage;
        this._startDate = searchCriteria.startDate ? this.dateFormat(searchCriteria.startDate, 'MM/DD/YYYY') : '';
        this._endDate = searchCriteria.endDate ? this.dateFormat(searchCriteria.endDate, 'MM/DD/YYYY') : '';
        this._customerRef = searchCriteria.customerRef ?? '';
        this._roomId = searchCriteria.roomId ?? '';
        this.accountId = accountId;
        this._accDateFormat = accDateFormat;
        this._timeZone = timeZone;
        this._language = language.replace('_', '-');
        this._wifiAnalyzerController = new WifiAnalyzerController();
    }

    get translate() {
        return this._translate;
    }

    get wifiAnalyzerController() {
        return this._wifiAnalyzerController;
    }

    @computed
    get wifiAnalyzerList() {
        return this._wifiAnalyzerList;
    }

    @computed
    get startDate() {
        return this._startDate;
    }

    @computed
    get endDate() {
        return this._endDate;
    }

    @computed
    get customerRef() {
        return this._customerRef;
    }

    @computed
    get selectedSession() {
        return this._selectedSession;
    }

    @computed
    get getDataOnInitialLoad() {
        return this._getDataOnInitialLoad;
    }

    @computed
    get isNextAvailable() {
        return this.currentPage < this._totalPages;
    }

    @computed
    get isPrevAvailable() {
        return this._currentPage > 1;
    }

    @computed
    get totalPages() {
        return this._totalPages;
    }

    @computed
    get currentPage() {
        return this._currentPage;
    }

    @computed
    get openModal() {
        return this._openModal;
    }

    @computed
    get roomId() {
        return this._roomId;
    }

    @action
    selectSession(session: IWifiAnalyzerResults) {
        this._selectedSession = session;
        this._getDataOnInitialLoad = true;
        this._wifiAnalyzerController.show();
        this._wifiAnalyzerController.setResults(session);
    }

    @action
    nextPage() {
        this._currentPage++;
        this.searchHistory();
    }

    @action
    prevPage() {
        this._currentPage--;
        this.searchHistory();
    }

    @action
    dateFormat(date: string, format: string) {
        return moment(date).format(format);
    }
    @action
    public async searchHistory(): Promise<void> {
        const pageNumber = this._currentPage;
        const startDateValue = this.startDate ? new Date(this.startDate) : undefined;
        const endDateValue = this.endDate ? new Date(this.endDate) : new Date();
        const customerRef = this.customerRef || undefined;
        const roomId = this.roomId || undefined;

        startDateValue?.setHours(0, 0, 0, 0);
        endDateValue.setHours(23, 59, 59, 999);

        if (startDateValue || customerRef || roomId) {
            const response = await getRootStore().wifiAnalyzerService.getWifiAnalyzerHistory(
                this.accountId,
                customerRef,
                startDateValue,
                endDateValue,
                PAGE_SIZE,
                pageNumber - 1
            );

            this.updateWifiAnalyzer(response);
        }
    }
    @action
    public updateWifiAnalyzer(newList: any): void {
        this._totalPages = Math.ceil((newList?.totalResults || 1) / PAGE_SIZE);
        this._wifiAnalyzerList = newList.previews;
    }

    @action
    getDateFormattedByAcc(date: string, withHours: boolean = true) {
        const fixedDate = withHours
            ? new Date(date).toLocaleString(this._language, {
                  timeZone: this._timeZone
              })
            : date;

        const format: string = withHours ? this._accDateFormat : this._accDateFormat.split(' ')[0];

        return getRootStore().getDateFormattedByAcc(fixedDate, format);
    }

    platformNameFormatted(deviceName: NATIVE_PLATFORM_TYPE) {
        return deviceName === NATIVE_PLATFORM_TYPE.android ? capitalize(deviceName) : toUpper(deviceName);
    }
}
